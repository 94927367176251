// eslint-disable-next-line
function giftBoxHelper(productItems, gift_selector_container) {
    if (productItems && productItems.length) {
        var items = productItems.filter(item => item.isGiftbox === true);
        if (!items.length) {
            $(gift_selector_container).addClass('d-none');
            // $('.minicart-gift-box').addClass('d-none');
        }
    }
}

module.exports = {
    giftBoxHelper
};
